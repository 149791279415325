import { Authentication } from '../models/auth';
import { Action, createReducer, on, createSelector, createFeatureSelector, ActionReducerMap } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as AuthActions from '../actions/auth.actions';

export interface AuthState extends EntityState<Authentication> {
  loading: boolean;
  error: { isError: boolean; status: number };
  selectedDeviceId: string | null;
}

export const authAdapter: EntityAdapter<Authentication> = createEntityAdapter<Authentication>();

// Define initial state
export const initialState: AuthState = authAdapter.getInitialState({
  // Additional entities state properties
  loading: false,
  error: { isError: false, status: null },
  selectedDeviceId: null
});
// Set initial state
export const authReducer = createReducer(
  initialState,
  on(AuthActions.authLogin, (state, {email, passwd}) => {
      const authObject: Authentication = {
        id: email,
        authToken: (email+passwd),
        expiry: 1,
        user: {
          username: email,
          password: passwd,
          emailAddress: email,
          firstName: 'default',
          lastName: 'user',
        }
      };
      return authAdapter.upsertOne(authObject, state);
  })
);

// Get the selectors
const {selectAll, selectEntities} = authAdapter.getSelectors();

// To use state from auth.reducer.ts in auth.service.ts
export const authSelector = createFeatureSelector<AuthState>('auth');
// To get the current state
export const selectAuthState = createSelector(authSelector, (state) => state);
// Get all the auths
export const selectAllAuths = createSelector(selectAuthState, selectAll);
// To get the current entities from the current state
export const selectDeviceEntities = createSelector(selectAuthState, selectEntities);
// Get auth by id
export const selectDeviceById = (id: string) => createSelector(
  selectDeviceEntities, (authEntities) => authEntities[id]
);
