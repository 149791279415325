import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RwcPageModule } from './rwc/rwc.module';
import { September6Component } from './components/september6/september6.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    // redirectTo: 'projects',
    redirectTo: 'login',
    // redirectTo: 'recipes',
    pathMatch: 'full'
  },
  {
    path: 'home',
    title: 'Home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'projects',
    title: 'Projects',
    loadChildren: () => import('./projects/projects.module').then( m => m.ProjectsPageModule)
  },
  {
    path: 'timeline',
    title: 'Timeline',
    loadChildren: () => import('./timeline/timeline.module').then( m => m.TimelinePageModule)
  },
  {
    path: 'additional-features',
    title: 'Additional Features',
    loadChildren: () => import('./additional-features/additional-features.module').then( m => m.AdditionalFeaturesPageModule)
  },
  {
    path: 'rwc',
    title: 'RWC Schedule',
    canActivate: [AuthGuard],
    loadChildren: () => import('./rwc/rwc.module').then( m => m.RwcPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'monitoring',
    loadChildren: () => import('./monitoring/monitoring.module').then( m => m.MonitoringPageModule)
  },
  {
    path: 'portugal',
    title: 'Portugal Schedule',
    canActivate: [AuthGuard],
    loadChildren: () => import('./portugal/portugal.module').then( m => m.PortugalPageModule)
  },
  {
    path: 'photos',
    title: 'Photos',
    canActivate: [AuthGuard],
    loadChildren: () => import('./photos/photos.module').then( m => m.PhotosPageModule)
  },
  {
    path: 'recipes',
    title: 'Recipes',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./recipes/recipes.module').then( m => m.RecipesPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
