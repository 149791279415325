import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map, catchError, switchMap, exhaustMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import * as AuthActions from '../actions/auth.actions';

@Injectable()
export class AuthEffects {
  authSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authSuccess),
      tap(() => {
        console.log('navigating');
        this.router.navigate(['/home']);
      })
    )
  );

  authFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authFail),
      tap(() => {
        this.router.navigate(['/login']);
      })
    )
  );

constructor(
    private actions$: Actions,
    private router: Router
  ) { }

}
