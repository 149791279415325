import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { concatMap, map, catchError, switchMap, exhaustMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import * as PropertyActions from '../actions/property.actions';


@Injectable()
export class PropertyEffects {
    constructor(
        private actions$: Actions,
        private router: Router
      ) { }
}
