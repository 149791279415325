import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map, catchError, switchMap, exhaustMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import * as DeviceActions from '../actions/device.actions';


@Injectable()
export class DeviceEffects {
//     loadDevices$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(DeviceActions.loadDevices),
//       concatMap(() =>
//       {
//         temperature: '25'
//       }
//         // this.deviceApi.index().pipe(
//         //   map((devices) => DeviceActions.loadDevicesSuccess({ devices })),
//         //   catchError(() => EMPTY)
//         // )
//       )
//     )
//   );

//   loadDevice$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(DeviceActions.loadDevice),
//       concatMap((action) =>
//         this.deviceApi.show(action.deviceId).pipe(
//           map((device) => DeviceActions.loadDeviceSuccess({ device })),
//           catchError(() => EMPTY)
//         )
//       )
//     )
//   );

//   addDevice$ = createEffect(() =>
//   this.actions$.pipe(
//     ofType(DeviceActions.addDevice),
//     exhaustMap((action) =>
//       this.deviceApi.createDevice(action.device).pipe(
//         switchMap((device) => [
//           DeviceActions.updateDeviceSuccess({ device }),
//           DeviceActions.addDeviceSuccess({ device, groupId: action.device.groupId })
//         ]),
//         catchError((error) => {
//           switch (error.status) {
//             case 409:
//               return of(DeviceActions.addDeviceFailed({ error: error.error }));

//             default:
//               return of(DeviceActions.addDeviceFailed({ error: 'Could not add device. Please ensure the correct ID is used.' }));
//           }
//         })
//       )
//     )
//   )
// );

// editDevice$ = createEffect(() =>
// this.actions$.pipe(
//   ofType(DeviceActions.editDevice),
//   exhaustMap((action) =>
//     this.deviceApi.editDevice(action.device, action.id).pipe(
//       switchMap((device) => [
//         DeviceActions.updateDeviceSuccess({ device }),
//         DeviceActions.editDeviceSuccess({ device, groupId: action.device.groupId })
//       ]),
//       catchError((error) => {
//         switch (error.status) {
//           case 403:
//             return of(DeviceActions.addDeviceFailed({ error: error.error }));

//           default:
//             return of(DeviceActions.addDeviceFailed({ error: 'Something went wrong.' }));
//         }
//       })
//     )
//   )
// )
// );

// deleteDevice$ = createEffect(() =>
// this.actions$.pipe(
//   ofType(DeviceActions.deleteDevice),
//   exhaustMap((action) =>
//     this.deviceApi.deleteDevice(action.deviceId).pipe(
//       map(() => DeviceActions.deleteDeviceSuccess({ deviceId: action.deviceId })),
//       catchError((error) => of(DeviceActions.deleteDeviceFailed({ deviceId: action.deviceId, error })))
//     )
//   )
// )
// );

constructor(
    private actions$: Actions,
    private router: Router
  ) { }

}
