import { createAction, props } from '@ngrx/store';
import { Authentication } from '../models/auth';

// Update Authentication
export const upsertUser = createAction('[Authentication] Upsert Authentication', props<{ authentication: Authentication }>());

export const authLogin = createAction('[Login] Login', props<{ email: string; passwd: string }>());
export const authSuccess = createAction('[Auth] Auth Success', props<{ authentication: Authentication }>());

export const loginSuccess = createAction('[Login] Login Success', props<{ authentication: Authentication}>());
export const loginFailure = createAction('[Login] Login Failure', props<{ error: { status: number } }>());




export const authFail = createAction('[Auth] Auth Failed');

export const logOut = createAction('[Auth] Log Out');
export const logOutSuccess = createAction('[Auth] Log Out Success');
export const logOutFailed = createAction('[Auth] Log Out Failed', props<{ error: any }>());

export const clearAuthToken = createAction('[Auth] Clear Auth Token');
