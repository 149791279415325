import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as authReducer from '../reducers/auth.reducer';
import { authLogin } from '../actions/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private store: Store<authReducer.AuthState>) {}

  authLogin(username, password) {
    // console.log(username);
    // console.log(password);
  this.store.dispatch(authLogin({email: username, passwd: password}));
  }

  // authSuccess(authObject) {
  //   this.store.dispatch(authSuccess({authentication: authObject}));
  // }

  getAuth(id: string) {
    return this.store.select(authReducer.selectDeviceById(id));
}

}
